.services {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__subtitle {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 100px;
    font-size: 36px;
    font-weight: 600;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 32%;
    padding-bottom: 53px;
    padding-top: 53px;
    border-radius: 12px;

    background: #fff;
  }

  &__card-image {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 56px;
    margin-bottom: 20px;

    background: #F5F3FE;
    border-radius: 16px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__card-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  &__card-text {
    max-width: 80%;
    font-size: 14px;
    text-align: center;
  }
  
  &--dark &__card-text, &--dark &__card-title {
    color: #232E35;
  }
}

@media (max-width: 620px) {
  .services {

    &__title {
      margin-bottom: 60px;
      font-size: 32px;
    }

    &__wrapper {
      flex-direction: column;
    }

    &__card {
      width: 100%;
      max-width: 320px;
      border-radius: 12px;

      &:nth-child(1) {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(3) {
        order: 3;
      }

      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }
}