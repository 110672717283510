.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;

  &__logo {
    display: flex;
    align-items: center;

    font-size: 18px;
    z-index: 3;

    svg {
      fill: #000;
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
  }

  &__logo-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px; /* Adjust margin as needed */
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__nav-item {
    font-size: 16px;

    a:hover {
      color: #FFA500 !important;

    }

    &:not(:last-child) {
      margin-right: 56px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__mode {
    margin-right: 36px;
    cursor: pointer;
    z-index: 3;
  }

  &__resume {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 102px;
    height: 49px;

    border: 1px solid #F1F1F1;
    border-radius: 8px;

    font-size: 14px;
    font-weight: 500;
    z-index: 3;

    &:hover {
      border: 1px solid #232E35;
    }
  }

  &__mobile-button {
    display: none;
    justify-content: center;
    align-items: center;

    width: 28px;
    height: 28px;

    position: relative;
    margin-left: 20px;
    z-index: 3;
  }

  &__mobile-button-button {
    width: 100%;
    height: 2px;

    background: #232E35;

    &:after, &:before {
      content: '';
      width: 100%;
      height: 2px;

      background: #232E35;

      position: absolute;
      left: 0;
      transition: all 0.3s ease;
    }

    &:after {
      top: 4px;
    }

    &:before {
      content: 'menu';
      background: transparent;
      bottom: 10px;
      color: #232E35;
      font-size: 10px;
    }
  }

  &__mobile-button.active &__mobile-button-button {
    background: transparent;

    &:after, &:before {
      top: 12px;
      bottom: 0;
      left: 0;
      right: 0;
      background: #232E35;
      font-size: 0;

    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(135deg);
    }
  }
  
  &__nav-item--resume {
    display: none;
  }

  &--dark &__nav-item a {
    color: #fff;
  }

  &--dark svg {
    fill: #fff;
  }
  
  &--dark span {
    color: #fff;
  }

  &--dark &__resume {
    color: #D7D7D7;
    border-color: #F1F1F1;

    &:hover {
      color: #FFA500;
      border-color: #FFA500;
    }
  }
  
  &--dark &__mobile-button-button {
    background: #fff;

    &:after, &:before {
      background: #fff;
      color: #fff;
    }

    &:before {
      background: transparent;
    }
  }
}

@media (max-width: 860px) {
  .header {

    &__mobile-button {
      display: flex;
    }

    &__nav {
      flex-direction: column;
      align-items: center;
      width: 100vw;
      padding: 6rem 1rem;
      transition: transform 0.7s;
      transform: translateY(-1000px);
      background: #fff;
      margin-left: 0;
      margin-right: 0;

      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }

    &__nav.active {
      transform: translateY(0);
    }

    &__nav-item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 18px;
    }

    &__nav-item {
      font-weight: 500;
    }
    
    &--dark &__nav {
      background: #000;
    }

    &--dark &__mobile-button.active &__mobile-button-button {

      &:after, &:before {
        background: #fff;
      }
    }
  }
}

@media (max-width: 620px) {

  .header {
    
    &__logo-text {
      flex-direction: row;
      margin-left: 0;
    }

    &__logo-text span {
      font-size: 0;
    }

    &__logo-text span:first-letter {
      font-size: 16px;
      font-weight: 600;
    }
    
    &__resume {
      display: none;
    }

    &__mode {
      margin-right: 0;
    }
    
    &__nav-item--resume {
      display: flex;
      font-weight: 600;
    }
  }

}