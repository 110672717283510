.portfolio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 120px;
  padding-bottom: 120px;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 100px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 36px;
    font-weight: 600;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 402px;

    border: 1px solid #F5F5F5;
    border-radius: 8px;
    overflow: hidden;

    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 100%;

      background: rgba(0, 0, 0, 0.37);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__card-image {
    width: 100%;
    height: 70%;

    img {
      width: 100%;
    }
  }

  &__card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 24px;
    background: #fff;
    z-index: 2;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__card-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }

  &__card-bottom {
    display: flex;
    align-items: center;
  }

  &__card-text {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 23px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 16px;

    font-size: 12px;
    font-weight: 500;
    color: #656D72;

    border: 1px solid #F1F1F1;
    border-radius: 4px;
  }

  &--dark &__card-title {
    color: #232E35;
  }

  &--dark &__card-text {
    color: #656D72;
  }
}

@media (max-width: 620px) {

  .portfolio {

    padding-top: 60px;
    padding-bottom: 60px;

    &__top {
      margin-bottom: 32px;
    }

    &__title {
      font-size: 28px;
    }
  }

  
}