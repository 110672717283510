.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 241px;

  padding-left: 20px;
  padding-right: 20px;

 // background: #fff;

  &--dark &__copyright {
    color: #fff;
   // background: #000;
  }
}