.social-list {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  &__item:not(:last-child) {
    margin-right: 32px;
  }

  svg {
    width: 24px;
    height: 24px;

    fill: #656D72;

    use {
      width: 100%;
      height: 100%;
    }

    &:hover {
      fill: #000;
    }
  }

  &--dark svg {
    fill: #232E35;

    &:hover {
      fill: #656D72;
    }
  }

  &__item--dark {
    fill: #fff !important;

    svg {
      fill: #fff !important;
    }
    &:hover, svg {
      fill: #7E74F1 !important;
    }
  }
}