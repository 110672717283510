.container {
  max-width: 1440px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grey {
  padding-top: 120px;
  padding-bottom: 120px;
  background: #FBFBFB;
//  transition: background-color 0.7s ease;
}
.subtitle {
  padding-left: 32px;

  font-size: 12px;
  font-weight: 500;
  color: #656D72;
  letter-spacing: 7.5px;
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    width: 16px;
    height: 2px;

    position: absolute;
    left: 0;
    top: 7px;

    background: #D9D9D9;
  }
}

* {
  $tl :0.7s;
  transition: background-color $tl ease, color $tl ease, border-color $tl ease, fill $tl ease;
  color: #232E35;
}

body.dark * {
  color: #fff;
}

.mySwiper {
  width: 100%;
}

body {
 // transition: background-color 0.7s ease;
}

body.dark {
  background: #282828;
  color: #fff;
}

body.dark .grey {
  background: #000;
}

@media (max-width: 575px) {
  .subtitle {
    margin-bottom: 10px;
  }
}

@media (max-width: 620px) {
  .grey {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 475px) {
  h1, h2 {
    font-size: 24px !important;
  }

  .subtitle {
    font-size: 10px;
    letter-spacing: 4px;
  }
}