.work {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      width: 38%;
      margin-right: 24px;
    }

    &:last-child {
      width: 60%;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 80px;
    font-size: 36px;
    font-weight: 600;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    max-width: 280px;
    width: 100%;
  }

  &__nav-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 52px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 4px;
    cursor: pointer;

    span {
      font-size: 16px;
      font-weight: 500;
    }

    span:last-child {
      font-size: 0;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__nav-item.active {

    background: #fff;

    span {
      color: #7E74F1;
    }

    span:last-child {
      font-size: 16px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
  }

  &__item-title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  &__place, &__time {
    margin-bottom: 16px;
    font-size: 14px;
    color: #656D72;
  }

  &__time {
    margin-bottom: 24px;
  }

  &__item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    padding-top: 32px;

    border-top: 1px solid #F1F1F1;
  }

  &__item-list-item {
    padding-left: 28px;
    font-size: 16px;
    color: #656D72;

    position: relative;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &:after {
      content: '';
      width: 10px;
      height: 2px;

      position: absolute;
      left: 0;
      top: 10px;

      background: #D9D9D9;
    }
  }
}

@media (max-width: 768px) {
  .work {

    &__title {
      font-size: 28px;
    }
  }
}

@media (max-width: 580px) {
  .work {
    flex-direction: column;

    &__title {
      margin-bottom: 20px;
    }

    &__column {
      width: 100% !important;
      margin-right: 0!important;

      &:first-child {
        margin-bottom: 32px;
      }
    }
    
    &__nav-item span:last-child {
      display: none;
    }
  }
}