.education {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-top: 120px;
  padding-bottom: 120px;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 72px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 36px;
    font-weight: 600;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &__column:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
    margin-right: 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 36px;
    padding-bottom: 64px;

    position: relative;

    &:after {
      content: '';

      width: 16px;
      height: 16px;
      border-radius: 50%;

      background: #F1F1F1;

      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      content: '';
      width: 1px;
      height: 100%;

      position: absolute;
      left: 8px;
      top: 14px;

      background: #F1F1F1;
    }
  }

  &__item:last-child:before {
    display: none;
  }

  &__item-title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  
  &__item-description {
    margin-bottom: 24px;
    font-size: 14px;
    color: #656D72;
  }

  &__item-time {
    font-size: 14px;
    font-weight: 500;
  }

  &__text {
    margin-bottom: 64px;
    font-size: 16px;
    color: #656D72;
  }

  &__skills {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__skill {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    span {
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__skill-progress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 8px;
    position: relative;
    background: #F5F3FE;

    &:before {
      content: '';
      height: 8px;

      position: absolute;
      left: 0;
      top: 0;

      background: #7E74F1;
      z-index: 1;
    }
  }

  &__skill-progress--50:before {
    width: 50%;
  }

  &__skill-progress--80:before {
    width: 80%;
  }

  &__skill-progress--90:before {
    width: 90%;
  }

  &__skill-progress--100:before {
    width: 100%;
  }
}

@media (max-width: 620px) {
  .education {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
      font-size: 28px;
    }

    &__wrapper {
      flex-direction: column;
    }

    &__column {
      width: 100%;

      &:first-child {
        flex-direction: row;
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    &__item {
      padding-left: 0;
      padding-right: 20px;
    }

    &__item:after {
      top: -32px;
      left: 10%;
    }

    &__item:before {
      top: -25px;
      left: 10%;
      width: 100%;
      height: 1px;
    }

    &__item-title {
      white-space: nowrap;
    }

    &__item-description {
      height: 80px;
      font-size: 13px;
    }
  }
}