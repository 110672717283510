.main-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
 // background: #fff;

  &__column:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    max-width: 540px;
    width: 100%;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 32px;
    font-size: 44px;
    font-weight: 600;
    span {
      color: #7E74F1 !important;
    }
  }

  &__text {
    margin-bottom: 40px;
    font-size: 16px;
  }

  &__column:last-child img {
    width: 100%;
  }
}

@media (max-width: 990px) {

  .main-block {

    &__title {
      font-size: 32px;
    }
  }
}

@media (max-width: 620px) {

  .main-block {
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;

    &__column:last-child {
      max-width: 320px;
    }

    &__column:first-child {
      width: 100%;
      margin-right: 0;
    }

    &__title {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 475px) {
  .main-block {

    &__text {
      font-size: 14px;
    }
  }
}