.contact-form {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__column {
    display: flex;
    flex-direction: column;


    &:first-child {
      width: 55%;
    }

    &:last-child {
      width: 38%;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 48px;
  }

  &__form-input {
    display: flex;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    input, textarea {
      display: flex;
      padding-left: 16px;
      padding-right: 16px;

      width: 100%;
      background: #fff;
      border-radius: 8px;
      outline: unset;
      border: unset;
      font-size: 16px;
    }

    input {
      align-items: center;
      height: 48px;
    }

    textarea {
      resize: unset;
      height: 190px;
    }
  }

  &__form-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 50px;

    background: #7E74F1;
    border-radius: 8px;

    font-size: 14px;
    font-weight: 500;
    color: #F5F3FE;
    cursor: pointer;
    outline: unset;
    border: unset;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  &__item-image {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 56px;
    margin-right: 24px;

    border-radius: 16px;
    background: #F5F3FE;
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    h4 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 500;
    }

    span, a {
      font-size: 14px;
      color: #656D72;
    }
  }
}

@media (max-width: 620px) {
  .contact-form {
    flex-direction: column;

    &__column {
      width: 100% !important;

      &:first-child {
        margin-bottom: 32px;
      }
    }
  }
}